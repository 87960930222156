






















































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfButton, SfGallery, SfHeading, SfLoader, SfPrice, SfRating
} from '~/components';

import {
  ref,
  computed,
  defineComponent,
  PropType, toRef,
} from '@nuxtjs/composition-api';

import {
  getSwatchData as getProductSwatchData,
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';

import {
  getTotalReviews,
  getAverageRating,
} from '~/modules/review/getters/reviewGetters';

import { useCart } from '~/modules/checkout/composables/useCart';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import BundleProductSelector from '~/modules/catalog/product/components/product-types/bundle/BundleProductSelector.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import AddToWishlist from '~/components/AddToWishlist.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { Product } from '~/modules/catalog/product/types';
import { TabsConfig, useProductTabs } from '~/modules/catalog/product/composables/useProductTabs';
import BundleProductOptionSkeleton
  from '~/modules/catalog/product/components/product-types/bundle/BundleProductOptionSkeleton.vue';

export default defineComponent({
  name: 'BundleProduct',
  components: {
    BundleProductOptionSkeleton,
    BundleProductSelector,
    HTMLContent,
    LazyHydrate,
    SfButton,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    AddToWishlist,
    SvgImage,
    ProductTabs,
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const qty = ref(1);
    const product = toRef(props, 'product');
    const { addItem, canAddToCart } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();
    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const productShortDescription = computed(
      () => props.product?.short_description?.html || '',
    );

    const productDescription = computed(
      () => props.product?.description?.html || '',
    );

    const productPrices = computed(() => getProductPrice(props.product));
    const customizationPrice = ref(productPrices.value.special ?? productPrices.value.regular);
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));

    return {
      addItem,
      addItemToWishlist: addOrRemoveItem,
      averageRating,
      canAddToCart,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      productDescription,
      productGallery,
      getProductName,
      getProductSwatchData,
      productPrices,
      customizationPrice,
      productShortDescription,
      qty,
      totalReviews,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
    };
  },
});
