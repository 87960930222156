

























































import {
  defineComponent,
  ref,
  onBeforeMount,
  computed,
  useContext, watch,
} from '@nuxtjs/composition-api';

import {
  SfButton, SfInput, SfSelect, SfTextarea, Rating
} from '~/components';
import reviewGetters from '~/modules/review/getters/reviewGetters';
import userGetters from '~/modules/customer/getters/userGetters';
import { useReview } from '~/modules/review/composables/useReview';
import { useUser } from '~/modules/customer/composables/useUser';
import type { ProductReviewRatingMetadata } from '~/modules/GraphQL/types';
import { usePageStore } from '~/stores/page';

import { ValidationObserver, ValidationProvider, setInteractionMode, extend } from 'vee-validate';
import { min, oneOf, required } from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

const BASE_FORM = (id) => ({
  nickname: '',
  ratings: {},
  sku: id,
  summary: '',
  text: '',
});

export default defineComponent({
  name: 'ProductAddReview',
  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfTextarea,
    ValidationProvider,
    ValidationObserver,
    Rating
  },
  emits: ['add-review'],
  setup(_, { emit }) {
    const { routeData } = usePageStore();

    // @ts-ignore
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );
    const {
      loading, loadReviewMetadata, error,
    } = useReview();
    const { isAuthenticated, user } = useUser();

    const reviewSent = ref(false);

    const form = ref(BASE_FORM(routeData.sku));

    const metadata = ref<ProductReviewRatingMetadata[]>([]);

    const ratingMetadata = computed(() => reviewGetters.getReviewMetadata([...metadata.value]));

    const formSubmitValue = computed(() => {
      const nickname = isAuthenticated.value
        ? userGetters.getFullName(user.value)
        : form.value.nickname;

      const ratings = Object.keys(form.value.ratings).map((key) => ({
        id: key,
        value_id: `${form.value.ratings[key]}`,
      }));
      return {
        ...form.value,
        nickname,
        ratings,
        recaptchaToken: '',
      };
    });

    const submitForm = (reset: () => void) => async () => {
      if (
        !(
          formSubmitValue.value.ratings[0].value_id
          || formSubmitValue.value.ratings[0].id
          || formSubmitValue.value.nickname
          || formSubmitValue.value.sku
          || formSubmitValue.value.text
        )
      ) {
        return;
      }
      try {
        if (isRecaptchaEnabled.value) {
          $recaptcha.init();
        }

        if (isRecaptchaEnabled.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          formSubmitValue.value.recaptchaToken = recaptchaToken;
        }

        reviewSent.value = true;

        emit('add-review', formSubmitValue.value);

        reset();

        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      } catch {
        reviewSent.value = false;
      }
    };

    const handleRatingClick = (metaid, id) => {
      form.value.ratings[metaid] = id;
      form.value = { ...form.value };
    };

    onBeforeMount(async () => {
      metadata.value = await loadReviewMetadata();
    });

    return {
      error,
      form,
      formSubmitValue,
      isAuthenticated,
      loading,
      ratingMetadata,
      reviewSent,
      submitForm,
      isRecaptchaEnabled,
      handleRatingClick
    };
  },
});
